import store from '@/store'
import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {auth} from '@/plugins/firebase.init';
import {applicationStore} from '@/store/modules/application';
import axios from '@/plugins/axios';
import {profileStore} from '@/store/modules/profile';
import {ArchiveState} from '@/domain/model/types';
import rfdc from 'rfdc';
import {appConfig, dynamicLinkConfig} from '@/plugins/firebase.config';
import {endAt, getDoc, getDocs, onSnapshot, updateDoc, orderBy, Query, query, startAt, where} from 'firebase/firestore'
import {businessCaseArchive, businessCasesArchive, chatMessages, mail} from '@/data/firebase';
import {dateToMDY, formatArchiveDate, phoneFormat, titleCase, sendEmail} from '@/utils/helpers';

const clone = rfdc({proto: true})

const defaultState: ArchiveState = {
  archive: [],
  selectedChatsArchive: null,
  selectedProfileArchive: null,
  archiveType: '',
  exporting: false,
  exportError: '',
  exportResult: null,
  caseMessages: [],
  archiveCountAll: 0,
  archiveCountPersonal: 0,
  archiveCache: {}
}

@Module({dynamic: true, name: 'archive-store', store})
export default class ArchiveStore extends VuexModule {
  private _archive: any[] = clone(defaultState).archive
  private _selectedChatsArchive: any = clone(defaultState).selectedChatsArchive
  private _selectedProfileArchive: any = clone(defaultState).selectedProfileArchive
  private _archiveType: string = clone(defaultState).archiveType
  private _exporting: boolean = clone(defaultState).exporting
  private _exportError: string = clone(defaultState).exportError
  private _exportResult: any = clone(defaultState).exportResult
  private _caseMessages: any[] = clone(defaultState).caseMessages
  private _archiveCountAll: number = clone(defaultState).archiveCountAll
  private _archiveCountPersonal: number = clone(defaultState).archiveCountPersonal
  private _archiveCache: any = clone(defaultState).archiveCache

  get archiveData() {
    return this._archive
  }

  get selectedArchive() {
    return this._archiveType === 'archive' ? this._selectedChatsArchive : this._selectedProfileArchive
  }

  get archiveType() {
    return this._archiveType
  }

  get archiveCount() {
    return this.archiveCountAll + this.archiveCountPersonal
  }

  get archiveCountAll() {
    const userId = profileStore.t2bUser?.id
    return this._archive.reduce((count, value) => {
      const inc = !value.memberIDs?.includes(userId)
      return inc ? ++count : count
    }, 0)
  }

  get archiveCountPersonal() {
    const userId = profileStore.t2bUser?.id
    return this._archive.reduce((count, value) => {
      const inc = value.memberIDs?.includes(userId)
      return inc ? ++count : count
    }, 0)
  }

  get exporting() {
    return this._exporting
  }

  get exportError() {
    return this._exportError
  }

  get exportResult() {
    return this._exportResult
  }

  get caseMessages() {
    return this._caseMessages
  }

  @Mutation
  private resetArchiveState() {
    Object.keys(defaultState).forEach((key) => {
      this['_' + key] = clone(defaultState)[key]
    })
  }

  @Mutation
  public setCaseMessages(messages: any[]) {
    this._caseMessages = messages
  }

  @Mutation
  public setArchiveType(type: string) {
    this._archiveType = type
  }

  @Mutation
  public setArchive(archive: any[]) {
    this._archive = archive
  }

  @Mutation
  private addArchiveItem(item: any) {
    if (!this._archive.find((value) => value.id === item.id)) {
      this._archive.push(item)
    }
  }

  @Mutation
  private updateArchiveItem(oldIndex: number, newIndex: number, item: any) {
    this._archive.splice(oldIndex, 1)
    this._archive.splice(newIndex, 0, item)
  }

  @Mutation
  private deleteArchiveItem(oldIndex: number) {
    this._archive.splice(oldIndex, 1)
  }

  @Mutation
  public setSelectedChatsArchive(archive: any) {
    this._selectedChatsArchive = archive
  }

  @Mutation
  public setSelectedProfileArchive(archive: any) {
    this._selectedProfileArchive = archive
  }

  @Mutation
  public setCachedArchive({archiveId, key}) {
    this._archiveCache[key] = archiveId
  }

  @Mutation
  public setExporting(value: boolean) {
    this._exporting = value
    this._exportError = value ? '' : this._exportError
  }

  @Mutation
  public setExportError(value: string) {
    this._exportError = value
  }

  @Mutation
  public setExportResult(data: any) {
    this._exportResult = data
  }

  @Mutation
  protected sortArchive() {
    this._archive.sort((a, b) => {
      const atime = a.updatedDate && a.updatedDate.toMillis();
      const btime = b.updatedDate && b.updatedDate.toMillis();
      return atime === btime ? 0 : atime > btime ? -1 : 1;
    })
  }

  @Action
  public async loadArchive() {
    const userId = profileStore.t2bUser?.id;
    const businessId = applicationStore.businessId
    if (!userId || !businessId) {
      return null
    }

    let archiveRef: Query = businessCasesArchive(businessId);
    if (!applicationStore.isAdmin) {
      archiveRef = query(archiveRef, where('memberIDs', 'array-contains', userId))
    }

    return onSnapshot(archiveRef, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const archiveItem = {id: change.doc.id, ...change.doc.data()}
        switch (change.type) {
          case 'added': {
            this.addArchiveItem(archiveItem)
            break
          }
          case 'modified': {
            this.updateArchiveItem(change.oldIndex, change.newIndex, archiveItem)
            break
          }
          case 'removed': {
            this.deleteArchiveItem(change.oldIndex)
            break
          }
          default:
        }
      })
      this.sortArchive()
    })
  }

  @Action
  public async loadArchiveItem(archiveId: string): Promise<any> {
    const userId = profileStore.t2bUser?.id;
    const businessId = applicationStore.businessId
    if (!userId || !businessId) {
      return null
    }
    const snapshot = await getDoc(businessCaseArchive(businessId, archiveId));
    return snapshot.exists() ? {id: snapshot.id, ...snapshot.data()} : null
  }

  @Action
  public async loadCaseMessages() {
    if (!this.selectedArchive) {
      return
    }
    const selectedCase = this.selectedArchive.case
    const chatId = selectedCase.textSessionId
    const openedDate = selectedCase.openedDate
    const closedDate = selectedCase.closedDate
    try {
      const snapshot = await getDocs(query(chatMessages(chatId),
        orderBy('createdDate'),
        startAt(openedDate),
        endAt(closedDate)));
      this.setCaseMessages(snapshot.docs.map((doc) => {
        return {id: doc.id, data: doc.data(), selected: false} //todo: revise for archive message view
      }))
    } catch (err) {
      console.error(err)
    }
  }

  @Action
  public async exportArchive(ids: string[]) {
    this.setExporting(true)
    try {
      const currentUser = auth.currentUser;
      const token = await currentUser?.getIdToken(false);
      const result = await axios.post(`/businesses/${applicationStore.businessId}/archive/export`, {ids}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const data = result.data;
      if (data.status === 'OK') {
        this.setExportResult(data)
      } else {
        this.setExportError(data.message)
      }
    } catch (err: any) {
      this.setExportError(err.message)
    }
    this.setExporting(false)
  }

  @Action
  public async selectArchive({archiveId, type, subtype}) {
    this.setArchiveType(type)
    const key = `${type}:${subtype}`;
    const chatsArchive = type === 'archive'
    if (!archiveId) {
      const firstArchive = this.archiveData[0];
      if (!firstArchive) {
        return;
      }
      if (chatsArchive) {
        if (!!this._selectedChatsArchive) {
          return;
        }
        this.setSelectedChatsArchive(firstArchive)
      } else {
        if (!!this._selectedProfileArchive) {
          return;
        }
        this.setSelectedProfileArchive(firstArchive)
      }
      this.setCachedArchive({archiveId: firstArchive.id, key})
      return
    }
    let archive = this.archiveData.find((item) => item.id === archiveId);
    if (!archive) {
      archive = await this.loadArchiveItem(archiveId)
      if (!archive) {
        return
      }
    }
    if (chatsArchive) {
      this.setSelectedChatsArchive(archive)
    } else {
      this.setSelectedProfileArchive(archive)
    }
    this.setCachedArchive({archiveId: archive.id, key})
  }

  @Action
  public findArchiveIdForType({type, subtype}) {
    const key = `${type}:${subtype}`;
    let archiveId = this._archiveCache[key];
    if (!archiveId) {
      const userId = profileStore.t2bUser?.id
      const personal = subtype === 'personal';
      archiveId = this.archiveData.find((item) => item.memberIDs?.includes(userId) === personal)?.id
      if (archiveId) {
        this._archiveCache[key] = archiveId
      }
    }
    return archiveId || ''
  }

  @Action
  public async shareArchive() {
    if (!this.selectedArchive) {
      return null;
    }
    const axiosResponse = await axios.post('/shortLinks', {
      dynamicLinkInfo: {
        domainUriPrefix: `https://${dynamicLinkConfig.actionCodeSettings.dynamicLinkDomain}/share/archive`,
        link: `${dynamicLinkConfig.actionCodeSettings.url}/profile/archive/${this.selectedArchive.id}?type=archive&subtype=personal`,
        androidInfo: {
          androidPackageName: dynamicLinkConfig.actionCodeSettings.android!!.packageName
        },
        iosInfo: {
          iosBundleId: dynamicLinkConfig.actionCodeSettings.iOS!!.bundleId
        }
      },
      suffix: {
        option: 'SHORT'
      }
    }, {
      baseURL: 'https://firebasedynamiclinks.googleapis.com/v1',
      params: {key: appConfig.apiKey},
      headers: {
        ['Content-Type']: 'application/json'
      },
      validateStatus: (status) => status === 200
    });
    return axiosResponse.data.shortLink
  }

  @Action
  public clearState() {
    this.resetArchiveState()
  }

  @Action
  public async sendArchiveEmail({toEmail, ids, subject}) {

    let html ='';    
    let bodyContent ='';
    let i:number = 0;
    this.setExporting(true)

    try{

      // subject      
      if(subject.includes('Export cases')){
        subject = subject.replace('Export cases for','Archived cases');        
      }else{
        subject = 'Archived cases';
      }      

      // html content
      if(toEmail && ids){

        // start html   
        html += '<!DOCTYPE html> <html> <head> <meta charset="UTF-8" />';
        html += '<style>';
        html += '.body-cs{font-family: Arial; font-size : 14px; line-height: 18px; } .bold{font-weight:bold} .space-header{ margin-bottom : 16px } .space-content{ margin-bottom : 32px } ';
        html += '.pigeon {margin: 30px 16px 52px 0;} .pigeon-logo {width: 120px; height: 35px;} a {text-decoration: none;} .footer {color: #364462; font-family: Inter, sans-serif; font-size: 14px; line-height: 22px; margin-top: 52px;}';
        html += '</style>';
        html += '</head> <body class="body-cs">';

        // logo
        html += '<div class="pigeon">'
        html += '<img src="https://firebasestorage.googleapis.com/v0/b/pigeon-website.appspot.com/o/assets%2Fpigeon_logo_email.png?alt=media&token=cdba14f3-fb63-4c34-a51d-842588d49f7f" class="pigeon-logo" alt="pigeon logo">'
        html += '</div>'
  
        // body content
        for(i=0; i<ids.length; i++){
          bodyContent += await this.makeEmailContent(ids[i]);
        }
        
        // footer
        bodyContent += '<div class="footer">Kind regards,<br><a href="mailto:support@getpigeon.com">Pigeon Support</a></div>'

        // end html
        html += bodyContent + '</body> </html>';
      }
      
      if(!bodyContent){  
        this.setExporting(false)    
        return false;        
      }

      // send email          
      const adminEmail = profileStore.t2bUser?.email || '';
      const currentUser = auth.currentUser;
      const token = currentUser ? await currentUser.getIdToken(false) : '';
      const userId = profileStore.t2bUser.id || ''; 

      // save html in db
      await updateDoc(mail('archive'), 
      'userId', '', 
      'html', ''); 

      await updateDoc(mail('archive'), 
      'userId', userId, 
      'html', html);      

      const result =  await sendEmail(userId, token, toEmail, adminEmail, subject, '', '', true)
      this.setExporting(false)
      return result;

    } catch (err: any) {
      console.log(err.message)      
      this.setExporting(false)
      return false;
    }   
  }
  
  @Action
  public async makeEmailContent(archiveId) {

    let html ='';
    const emptyText = 'N/A';
    
    try {

      let archive = this.archiveData.find((item) => item.id === archiveId);
      if (!archive) {
        return
      }

      const selectedCase = archive.case
      const chatId = selectedCase.textSessionId
      const openedDate = selectedCase.openedDate
      const closedDate = selectedCase.closedDate

      const snapshot = await getDocs(query(chatMessages(chatId),
        orderBy('createdDate'),
        startAt(openedDate),
        endAt(closedDate)));
      
      let messages = snapshot.docs.map((doc) => {
        return {id: doc.id, data: doc.data(), selected: false} 
      })      

      //make html        
      let caseDate = dateToMDY(openedDate.toDate());
      let startDate = formatArchiveDate(openedDate.toDate());
      let endDate = formatArchiveDate(closedDate.toDate());

      let customer = titleCase(selectedCase.customer.name)
      let customerId = selectedCase.customer.id   
      let associate = titleCase(selectedCase.associate?.name)
      let atText = applicationStore.businessName
      
      let customerEmail = archive.customer.email || emptyText
      let customerPhone = phoneFormat(archive.customer?.phoneNumber) ||  emptyText
      let dob = archive.customer?.dob || emptyText

      // title
      html += '<br />'
      html += '<div>' + '[Case date] ' + caseDate + '</div>'
      html += '<div>' + '[Customer name] ' + customer + '</div>'
      html += '<div>' + '[DOB] ' + dob + '</div>'
      html += '<div>' + '[User ID] ' + customerId + '</div>'
      html += '<div>' + '[Email] ' + customerEmail + '</div>'
      html += '<div class="space-header">' + '[Phone] ' + customerPhone + '</div>'      

      // start
      html += '<div class="bold">' + '[Case start] ' + startDate + '</div>'
      html += '<div>' + customer + ' writes to ' + associate + ' at ' + atText + '</div>'     

      // text
      messages.forEach(message => {
        let sender = message.data.sender.name
        let senderId = message.data.sender.uid
        let createdDate = formatArchiveDate(message.data.createdDate.toDate()) 
        let text = message.data.text        
        let isSMS = message.data?.isSMS === true ? true : false;        
        let viaText = 'Live Chat'

        if(isSMS)
          viaText = 'SMS'
        else if(senderId == customerId)
          viaText = 'Live Chat'
        else
          viaText = 'In-app'

        html += '<div>' + sender + ': ' +  text + ' [' + createdDate + ' via ' + viaText + ']' + '<div>'
      })

      // end
      html += '<div>' + associate + ' closed the case' + '</div>'
      html += '<div class="bold space-content">' + '[Case end] ' + endDate + '</div>'

      return html;
      
    } catch (err) {
        console.error(err)
        return '';      
    }    
  }

}

export const archiveStore = getModule(ArchiveStore)
